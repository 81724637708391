import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { toggleSignupForm } from '../../store/actions';
import './index.scss';

export default function MessageAfterBody() {
  const dispatch = useDispatch();

  const handleSignup = () => {
    dispatch(toggleSignupForm());
  }

  return (
    <div className='message-after-body'>
      <div className='message-after-body__content'>
        <div>
          <h2>Ainda não faz parte da BFull?</h2>
          <h3>Vamos começar</h3>
        </div>
        <div>
          <Button className='btn btn-square' onClick={handleSignup}>Cadastre-se</Button>
        </div>
      </div>
    </div>
  )
}