/*
 * action types
 */
export const SHOW_SIGNUP_FORM   = 'SHOW_SIGNUP_FORM'
export const HIDE_SIGNUP_FORM   = 'HIDE_SIGNUP_FORM'
export const TOGGLE_SIGNUP_FORM = 'TOGGLE_SIGNUP_FORM'

export function showSignupForm() {
  return { type: SHOW_SIGNUP_FORM }
}

export function hideSignupForm() {
  return { type: HIDE_SIGNUP_FORM }
}

export function toggleSignupForm() {
  return { type: TOGGLE_SIGNUP_FORM }
}