import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import banner from '../../assets/images/truck-bfull.svg';
import dots6x19 from '../../assets/images/dots_6x19.svg';
import dots4x4 from '../../assets/images/dots_4x4.svg';
import { toggleSignupForm } from '../../store/actions';
import leftBottomBannerEffect from '../../assets/images/left-bottom-banner-effect.svg';
import './index.scss';

export default function Banner() {
  const dispatch = useDispatch();
  
  const handleSignup = () => {
    dispatch(toggleSignupForm());
  }

  return (
    <div className="app__banner">
      <div className="app__banner__content">
        <h1>Um jeito novo de transportar</h1>
        <p>Processos simples, integrados e com mais
        transparência na hora de carregar.
      </p>
        <Button className='btn btn-square' variant='outlined' onClick={handleSignup}>Cadastre-se</Button>
      </div>
      <div className="app__banner__image">
        <div style={{ width: '35rem', padding: '0.88rem' }}>
          <img src={dots6x19} style={{ position: 'absolute', width: '45px', top: 45, left: -33 }} alt='dots' />
          <img src={banner} style={{ width: '100%', boxShadow: 'rgb(117, 117, 117) -15px 20px 15px -15px' }} alt='Banner caminhão' />
          <img src={dots4x4} style={{ position: 'absolute', bottom: 0, left: 0, zIndex: -1, height: 30 }} alt='dots' />
          <img src={leftBottomBannerEffect} style={{ position: 'absolute', bottom: -15, left: -15, zIndex: -2}} alt="Moldura do banner"/>
        </div>
      </div>
    </div>
  )
}