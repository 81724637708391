import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";
import { StaticRouter } from  'react-router';
import Home from './components/Pages/Home';
import ActiveAccount from './components/Pages/ActiveAccount';
const isServer = typeof window === 'undefined';

function Router() {

  if (isServer) {
    return (
      <StaticRouter>
        <Home />
      </StaticRouter>
    );
  }
  
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/account/active">
          <ActiveAccount />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Router;