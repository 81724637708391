import axios from 'axios';

export default function CarrierHandler() {
  const baseURL = ((process.env.NODE_ENV === 'development') ? 
    'http://localhost:3333' :
    process.env.REACT_APP_API
  );
  return {
    savePassword: (token, data) => {
      return new Promise(async (resolve, reject) => {
        axios.post(baseURL.concat('/account/active/' + token), data).then((response) => {
          resolve(response.data);
        }).catch(reject);
      });
    }
  }
}