import React from 'react';
import idIcon from '../../assets/images/id.png';
import truckIcon from '../../assets/images/truck.png';
import doneIcon from '../../assets/images/done.png';
import './index.scss';

export default function HowWorksBox() {
  return (
    <div className='how-works-box'>
      <h2>Como funciona?</h2>
      <div className='how-works-box__items'>
        <div className='how-works-box__items__item'>
          <div className="how-works-box__items__item__content">
            <img src={idIcon} alt="Icone indentificação" />
            <h3>Cadastre sua carga</h3>
            <p>O cadastro da carga pela plataforma é feito de maneira simples,
        via email ou pela opção Cadastre sua carga dentro da plataforma</p>
          </div>
        </div>

        <div className='how-works-box__items__item'>
          <div className="how-works-box__items__item__content">
            <img src={truckIcon} alt="Icone Truck" />
            <h3>Localiza a transportadora</h3>
            <p>Através da nossa plataforma você encontra a transportadora ideal
            para realizar seu frete de maneira rápida e eficiente. Podendo
        realizar todo o gerenciamento de frete em um único lugar</p>
          </div>
        </div>

        <div className='how-works-box__items__item'>
          <div className="how-works-box__items__item__content">
            <img src={doneIcon} alt="Icone Documentação" />
            <h3>Emissão e troca de documentação</h3>
            <p>A emissão da documentação legal do frete
            (CT-e, manifesto eletrônico e seguro de carga) são emitidos
            pela transportadora e disponibilizados na plataforma para a indústria
            </p>
          </div>
        </div>

      </div>
    </div>
  );
}