import React, { useState, useEffect } from 'react';
import Header from '../../Header';
import Footer from '../../Footer';
import Button from '@material-ui/core/Button';
import PasswordValidator from 'password-validator';
import SignupForm from '../../SignupForm';
import CarrierHandler from '../../../api/CarrierHandler';
import userPassowrdIcon from '../../../assets/images/users_password_icon.png';
import { SuccessIcon } from '../../SuccessIcon';
import './index.scss';

function ActiveAccount() {
  const [isValid, setValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('As senhas não conferem');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [success, setSuccess] = useState(false);

  const getPasswordValidatorSchema = () => {
    const schema = new PasswordValidator();
    return schema
      .is().min(8)
      .is().max(100)
      .has().lowercase()
      .has().digits(1)
      .has().not().spaces()
      .is().not().oneOf(['Passw0rd', 'Password123']);
  }

  const getToken = () => {
    const query = new URLSearchParams(window.location.search);
    return query.get('token');
  }

  const handleSavePassword = () => {
    const token = getToken();
    CarrierHandler().savePassword(token, {
      password,
      confirmPassword
    }).then((response) => {
      setSuccess(true);
    }).catch((error) => {
      setValid(false);
      setErrorMessage('Serviço temporariamente indisponível, contate o adminstrador.');
      if (error.response) {
        const { data } = error.response;
        if(data[0].message && data[0].code && data[0].code === 102){
          setErrorMessage(data[0].message);
        }
      }
    });
    return false;
  }

  const handleLogin = () => {
    window.location.href = process.env.REACT_APP_APP_DASHBOARD_URL || '#';
  }

  useEffect(() => {
    if (password.length > 0 && confirmPassword.length > 0) {
      if (password === confirmPassword) {
        const schema = getPasswordValidatorSchema();
        setErrorMessage('Sua senha não segue as especificações necessárias (deve ter pelo menos 8 caracteres\n' +
          'incluindo: letras(maiúscula e minúsculas), números, caracteres especiais (Ex: - + # % @))');
        setValid(schema.validate(password));
      } else {
        setErrorMessage('As senhas não conferem');
        setValid(false);
      }
    } else {
      setValid(true);
    }
  }, [password, confirmPassword]);

  return (
    <>
      <main className='page'>
        <div className='container'>
          <div className='background-white'></div>
          <div className='square-background' style={{ height: '6rem' }}></div>
          <div className='content'>
            <Header text={'Faça login em sua conta'} hasRegister={false} />

            {
              success ?
                (
                  <div className='success-container'>
                    <SuccessIcon message={'Senha atualizada com sucesso!'} />
                    <Button
                      className='btn btn-signin'
                      variant="outlined"
                      style={{ margin: '20px 0' }}
                      onClick={handleLogin}
                    >
                      Efetuar login
                      </Button>
                  </div>
                ) :
                (<div className="savepass-container">
                  <div className={'savepass-container__step'}>
                    <h1 style={{ color: '#13A89E' }}>Hora de cadastrar sua senha</h1>
                    <div className={'savepass-container__step__content'} >
                      <h2 style={{ color: '#757575', fontWeight: '100' }}>Cadastre sua senha:</h2>
                      <div style={{ marginBottom: '20px' }}>
                        <input className={(!isValid) ? 'error' : ''} placeholder={'Senha'} type={'password'}
                          onChange={(event) => setPassword(event.target.value)} />
                      </div>
                      <div style={{ marginBottom: '20px' }}>
                        <input className={(!isValid) ? 'error' : ''} type={'password'} placeholder={'Confirmar senha'}
                          onChange={(event) => setConfirmPassword(event.target.value)}
                        />
                        {!isValid && <p className={'input-box-error'}>{errorMessage}</p>}
                      </div>
                      <div style={{ marginBottom: '50px' }}>
                        <Button
                          onClick={handleSavePassword}
                          className='btn default btn-save-password'
                          variant="outlined">
                          Salvar senha
                        </Button>
                      </div>
                      <p style={{ color: '#757575', fontSize: '14px' }}>
                        Sua senha deve ter pelo menos 8 caracteres incluindo
                  </p>
                      <p style={{ color: '#757575', fontSize: '12px', margin: 0 }}>Letras(maiúscula e minúsculas)</p>
                      <p style={{ color: '#757575', fontSize: '12px', margin: 0 }}>Números</p>
                      <p style={{ color: '#757575', fontSize: '12px', margin: 0 }}>Caracteres especiais (Ex: - + # % @)</p>
                    </div>
                  </div>
                  <div className="wrapper-userpassicon">
                    <img src={userPassowrdIcon} alt="Icone cadastro"/>
                  </div>
                </div>)
            }
          </div>
        </div>
      </main>
      <SignupForm />
      <Footer />
    </>
  );
}

export default ActiveAccount;