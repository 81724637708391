import React from 'react';
import Paper from '@material-ui/core/Paper';
import locationIcon from '../../assets/images/placeholder.png';
import moneyBagIcon from '../../assets/images/money-bag.png';
import surfaceIcon from '../../assets/images/surface.png';
import timeIcon from '../../assets/images/time.png';
import fileIcon from '../../assets/images/file.png';
import './index.scss';

export default function AdvantageCards() {

  return (
    <div className='advantage-cards'>
      <div className="container">
        <h2>Por que escolher a BFull?</h2>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>

          <div className='advantage-cards__card'>
            <Paper elevation={2} className='advantage-cards__card__content red'>
              <div className='row'>
                <img src={locationIcon} alt='localização' />
                <div>
                  <h4>Monitoramento da Carga</h4>
                  <p>Acompanhamento em tempo real da carga desde o momento da retirada até a entrega final</p>
                </div>
              </div>
            </Paper>
          </div>

          <div className='advantage-cards__card'>
            <Paper elevation={2} className='advantage-cards__card__content yellow1'>
              <div className='row'>
                <img src={timeIcon} alt='tempo' />
                <div>
                  <h4>Economia de Tempo</h4>
                  <p>Conecte-se com indústria e transportadoras em pouco tempo</p>
                </div>
              </div>
            </Paper>
          </div>

          <div className='advantage-cards__card'>
            <Paper elevation={2} className='advantage-cards__card__content green'>
              <div className='row'>
                <img src={surfaceIcon} alt='segurança' />

                <div>
                  <h4>Segurança</h4>
                  <p>Análise da transportadora e da indústria, antes de fazerem parte da plataforma B-Full</p>
                </div>
              </div>
            </Paper>
          </div>

          <div className='advantage-cards__card'>
            <Paper elevation={2} className='advantage-cards__card__content yellow2'>
              <div className='row'>
                <img src={moneyBagIcon} alt='economia' />
                <div>
                  <h4>Economia de Recursos</h4>
                  <p>Economize e otimize recursos com as vantagens oferecidas pela B-Full</p>
                </div>
              </div>
            </Paper>
          </div>

          <div className='advantage-cards__card'>
            <Paper elevation={2} className='advantage-cards__card__content grey'>
              <div className='row'>
                <img src={fileIcon} alt='burocracia' />
                <div>
                  <h4>Menos Burocracia</h4>
                  <p>Toda troca de documentação e mensagens são
          realizadas através de plataforma, trazendo agilidade e facilidade para a operação</p>
                </div>
              </div>
            </Paper>
          </div>

        </div>
      </div>
    </div>
  );
}
