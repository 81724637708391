import { createStore } from 'redux';
const INITIAL_STATE = {
  signupForm: {
    visible: false
  }
};

function reducer(state = INITIAL_STATE, action) {

  switch (action.type) {
    case 'SHOW_SIGNUP_FORM': {
      return {
        ...state,
        signupForm: {
          visible: true
        }
      }
    }
    case 'HIDE_SIGNUP_FORM': {
      return {
        ...state,
        signupForm: {
          visible: false
        }
      }
    }
    case 'TOGGLE_SIGNUP_FORM': {
      return {
        ...state,
        signupForm: {
          visible: !state.signupForm.visible
        }
      }
    }
    // no default
  }
  return state;

}

const store = createStore(reducer);
export default store;