import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import logo from '../../assets/images/logo.png';
import { toggleSignupForm } from '../../store/actions';
import arrowRight from '../../assets/images/arrow_right.svg';
import './index.scss';

export default function Header({ 
  text = 'Clique aqui e seja um dos primeiros a fazer parte', 
  hasRegister = true
}) {
  const [isFixedNax, setFixedNav] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setFixedNav(window.scrollY >= 85);
    });
    return () => {
      window.removeEventListener('scroll');
    }
  }, []);

  const handleSignup = () => {
    dispatch(toggleSignupForm());
  }

  const handleLogin = () => {
    window.location.href = process.env.REACT_APP_APP_DASHBOARD_URL || '#';
  }

  return (
    <header className='app__header'>
      <div>
        <img src={logo} className='logo' alt='logo' />
      </div>
      <div>
        <ul className={`app__header__nav ${(isFixedNax) ? 'app__header__nav--fixed' : ''}`}>
          <li style={{marginRight: (hasRegister) ? '3rem' : '0'}}>
            <img src={logo} className='logo' alt='logo' />
            <div className='app__header__nav__message'>
              <p>{text}</p>
              <img src={arrowRight} style={{ width: 15, height: 15 }} alt='Seta para direita' />
            </div>
          </li>
          <li style={{width: (hasRegister) ? '15rem' : 'auto'}}>
            {hasRegister && <Button className='btn default' variant="outlined" onClick={handleSignup}>Faça seu cadastro!</Button>}
            <Button className='btn btn-singup' variant="outlined" onClick={handleLogin}>Login</Button>
          </li>
        </ul>
      </div>
    </header>
  )
}