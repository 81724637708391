import React, { useState, useEffect, createRef } from 'react';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import { SuccessIcon } from '../SuccessIcon';
import Button from '@material-ui/core/Button';
import ReCAPTCHA from "react-google-recaptcha";
import { cpf, cnpj } from 'cpf-cnpj-validator';
import RadioGroup from '@material-ui/core/RadioGroup';
import InterestedHandler from '../../api/InterestedHandler';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './index.scss';

export default function SignupForm() {
  const gIsVisible = useSelector(state => state.signupForm.visible);
  const [isVisible, setVisible] = useState(false);
  const [isFixedNax, setFixedNav] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const [formData, setFormData] = useState({ 
    firstname: '',
    lastname: '',
    phone: '',
    cpf: '',
    cnpj: '',
    company: '',
    email: '',
    typeOfBusiness: 'carrying'
  });
  const [errorMessage, setErrorMessage] = useState('');
  const recaptchaRef = createRef();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setFixedNav(window.scrollY >= 85);
    });
    return () => {
      window.removeEventListener('scroll');
    }
  }, []);

  useEffect(() => {
    setVisible(gIsVisible);
  }, [gIsVisible]);

  const resetFormData = () => {
    setFormData({ 
      firstname: '',
      lastname: '',
      phone: '',
      cpf: '',
      cnpj: '',
      company: '',
      email: '',
      typeOfBusiness: 'carrying'
    });
  }

  const handleChange = (label) => {
    return (event) => {
      setFormData({
        ...formData,
        [label]: event.target.value
      });
    }
  }

  const handleCloseSuccess = () => {
    setSuccess(false);
  }

  const validateForm = (token = null) => {
    let error = false;
    const errorMessages = {
      company: 'Por favor, infome o nome da empresa',
      email: 'Por favor, informe seu email',
      phone: 'Por favor, informe seu telefone',
      lastname: 'Por favor, informe seu sobrenome',
      firstname: 'Por favor, informe seu nome',
    };


    if(!cnpj.isValid(formData.cnpj)) {
      setErrorMessage('Por favor, informe um CNPJ válido!');
      error = true;
    }


    if(!cpf.isValid(formData.cpf)) {
      setErrorMessage('Por favor, informe um CPF válido!');
      error = true;
    }

    if(!token) {
      setErrorMessage('Ocorreu um erro. Por favor, tente novamente mais tarde.');
      error = true;
    }

    Object.keys(errorMessages).forEach((field) => {
      if(formData[field].trim().length === 0) {
        const message = errorMessages[field];
        setErrorMessage(message);
        error = true;
      }
    });

    setError(error);
    return !error;
  }

  const handleSaveInterested = () => {
    const recaptcha = recaptchaRef.current;
    const token = recaptchaRef.current.getValue();
    if(validateForm(token)){
      InterestedHandler().save({
        ...formData,
        is_carrying: (formData.typeOfBusiness === 'carrying'),
        is_industry: (formData.typeOfBusiness === 'industry'),
        token
      }).then((response) => {
        resetFormData();
        setSuccess(true);
      }).catch((error) => {
        const data = error.response.data;
        setError(true);
        setErrorMessage(data[0].message);
        recaptcha.reset();
      });
    }
  }

  return (
    <aside className={`signup ` + (!isVisible ? ' display-none' : '') + (isFixedNax ? ' fixed-nav' : '')}>
      <div className='container signup__box'>
        <div className='signup__box__content'>
          <div className='title'>
            <h2>Cadastro</h2>
          </div>

          <div className='form'>
            <div className={`success-box ` + (isSuccess ? 'success-box--visible' : '')}>
              <button type="button" className="close" onClick={handleCloseSuccess}>
                <span aria-hidden="true">×</span>
              </button>
              <SuccessIcon message={'Cadastro realizado com sucesso!'} color='#fff' />
            </div>

            <div className={isSuccess ? 'display-none' : ''}>
              <span>É grátis e em apenas 1 minuto você concluí seu cadastro!</span>

              {(isError) && <Alert variant="filled" severity="error" style={{ marginTop: '1rem' }}>{errorMessage}</Alert>}

              <input type='text' placeholder='Nome' value={formData.firstname||''} onChange={handleChange('firstname')} />
              <input type='text' placeholder='Sobrenome' value={formData.lastname||''} onChange={handleChange('lastname')} />
              <InputMask placeholder='CPF' mask="999.999.999-99" value={formData.cpf||''} onChange={handleChange('cpf')} maskChar=" " />
              <InputMask placeholder='Telefone celular' value={formData.phone||''} onChange={handleChange('phone')} mask="(99) 9999-9999?"
                formatChars={{ "9": "[0-9]", "?": "[0-9]" }} maskChar=" " />
              <input type='email' placeholder='Email corporativo' value={formData.email||''} onChange={handleChange('email')} />
              <input type='text' placeholder='Nome da empresa' value={formData.company||''} onChange={handleChange('company')} />
              <InputMask placeholder='CNPJ' mask="99.999.999/9999-99" value={formData.cnpj||''} maskChar=" " onChange={handleChange('cnpj')} />
              <RadioGroup aria-label="typeOfBusiness" value={formData.typeOfBusiness} name="typeOfBusiness" onChange={handleChange('typeOfBusiness')} >
                <FormControlLabel value="carrying" control={<Radio />} label="Sou transportadora, quero fretes" />
                <FormControlLabel value="industry" control={<Radio />} label="Sou indústria, preciso transportar" />
              </RadioGroup>

              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LcI7vIUAAAAAKUvywRpLRwz3Nl2CA22f2dwpXhb"
              />

              <Button onClick={handleSaveInterested} className='btn default btn-signup' variant='outlined'>Faça seu cadastro!</Button>
            </div>
          </div>
        </div>
      </div>
    </aside>
  )
}