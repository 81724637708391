import React from 'react';
import womanIcon from '../../assets/images/woman.svg';
import './index.scss';

export default function MessageCard() {
  return (
    <div className='message-box'>
      <div className='message-card'>
        <div className='text'>
          <p>Nosso algoritmo analisa seus fretes e cruza com as transportadoras, buscando sempra as melhores oportunidades para você.</p>
        </div>
        <div className='icon-box'>
          <img src={womanIcon} alt="Icone de usuários" />
        </div>
      </div>
    </div>
  )
}