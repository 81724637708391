import React from 'react';
import Header from '../../Header';
import Banner from '../../Banner';
import Footer from '../../Footer';
import SignupForm from '../../SignupForm';
import MessageCard from '../../MessageCard';
import HowWorksBox from '../../HowWorksBox';
import AdvantageCards from '../../AdvantageCards';
import MessageAfterBody from '../../MessageAfterBody';
import TypeOfBusinessBox from '../../TypeOfBusinessBox';

function Home() {
  return (
    <>
      <main className='page'>
        <div className='container'>
          <div className='background-white'></div>
          <div className='square-background'></div>
          <div className='content'>
            <Header />
            <Banner />
            <MessageCard />
            <TypeOfBusinessBox />
            <HowWorksBox />
          </div>
        </div>
        <AdvantageCards />
        <MessageAfterBody />
      </main>
      <SignupForm />
      <Footer />
    </>
  );
}

export default Home;