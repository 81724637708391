import React, { Component } from 'react';
import './index.scss';

export class SuccessIcon extends Component
{
  constructor(props){
    super(props);
    this.state = {
      hasMessage: props.hasOwnProperty('message') || props.message.trim().length > 0,
      message: (props.hasOwnProperty('message') && props.message.trim().length > 0) ? props.message : '',
      color: (props.hasOwnProperty('color')) ? props.color : '#26a69a' 
    } 
  }

  render() {
    const style = {
      color: this.state.color
    }
    return (
      <div className="success-icon">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
          <circle className="path circle" fill="none" stroke={this.state.color}
            strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
          <polyline className="path check" fill="none" stroke={this.state.color}
            strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10"
            points="100.2,40.2 51.5,88.8 29.8,67.5 " />
        </svg>
        { (this.state.hasMessage) ? (<p style={style} >{this.props.message}</p>) : '' }
      </div>
    );
  }
}