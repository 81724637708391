import React from 'react';
import bannerCarrying from '../../assets/images/banner_carrying.png';
import bannerIndustry from '../../assets/images/banner_industry.png';
import { toggleSignupForm } from '../../store/actions';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import './index.scss';

export default function TypeOfBusinessBox() {
  const dispatch = useDispatch();

  const handleSignup = () => {
    dispatch(toggleSignupForm());
  }

  return (
    <div className='type-of-business'>

      <div className='type-of-business__box'>
        <div className='type-of-business__box__data'>
          <h4 className='type-of-business__box__data_title'>Indústria</h4>
          <Button className='type-of-business__box__data_button btn-red' onClick={handleSignup}>Faça seu cadastro</Button>
        </div>
        <img src={bannerIndustry} className='type-of-business__box_background' alt='Industria' />
      </div>

      <div className='type-of-business__box'>
        <div className='type-of-business__box__data'>
          <h4 className='type-of-business__box__data_title'>Transportador</h4>
          <Button className='type-of-business__box__data_button btn-green' onClick={handleSignup}>Veja nossas cargas</Button>
        </div>
        <img src={bannerCarrying} className='type-of-business__box_background' alt='Transportadora' />
      </div>
      
    </div>
  );

}