import React from 'react';
import logo from '../../assets/images/logo.png';
import facebook from '../../assets/images/facebook.svg';
import linkedin from '../../assets/images/linkedin.svg';
import instagram from '../../assets/images/instagram.png';
import './index.scss';

export default function Footer() {

  return (
    <footer>
      <div className="container">
        <div className="footer">
          <div>
            <img src={logo} alt='Logo' />
            <div style={{ textAlign: 'right', fontSize: '12px' }}>© 2020</div>
          </div>
          <ul>
            <li>Como funciona</li>
            <li>Vantagens</li>
          </ul>
          <ul>
            <li>Acesse sua conta</li>
            <li>Crie sua conta</li>
          </ul>
          <div>
            <p>Siga-nos</p>
            <div>
              <div>
                <img src={instagram} className='social-icon' alt='Icone Instagram' />
                <img src={linkedin} className='social-icon' alt='Icone Linkedin' />
                <img src={facebook} className='social-icon' alt='Icone Facebook' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}